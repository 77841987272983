@tailwind base;
@tailwind components;
@tailwind utilities;

/* Survicate */
.sv__text-question {
  font-size: 16px !important;
}

/* https://github.com/vercel/next.js/issues/18915#issuecomment-1005128405 */
.image-block-hack > span {
  display: block !important;
}

.common {
  background-image: url("/img/bg/common.jpeg");
  background-color: #6ed550;
  /* background-image: url("https://static.vecteezy.com/system/resources/previews/001/874/917/non_2x/light-green-template-with-sky-clouds-vector.jpg");
  background-color: #99db88;
  background-blend-mode: multiply; */
  /* background-image: url("https://static.vecteezy.com/system/resources/previews/002/626/202/non_2x/light-green-background-with-colorful-stars-colorful-illustration-with-abstract-gradient-stars-design-for-your-business-promotion-vector.jpg"); */
}
.common-flat {
  background-color: #6ed550;
}

.unique {
  background-image: url("/img/bg/space.jpg");
  /* background-color: #560fbd; */
  background-color: #21175e;
}
.unique-flat {
  background-color: #21175e;
}

.legendary {
  background-image: url("/img/bg/legendary.jpeg");
  background-color: #e050de;
  /* background-image: url("https://static.vecteezy.com/system/resources/previews/013/437/502/non_2x/light-purple-pink-pattern-with-abstract-stars-vector.jpg");
  background-color: #c46ef5; */
}
.legendary-flat {
  background-color: #e050de;
}

.epic {
  background-image: url("/img/bg/epic.jpeg");
  /* background-image: url("https://static.vecteezy.com/system/resources/previews/013/655/508/non_2x/light-blue-background-with-small-and-big-stars-vector.jpg"); */
  background-color: #08d8fe;
}
.epic-flat {
  background-color: #08d8fe;
}

.rare {
  background-image: url("/img/bg/rare.jpeg");
  /* background-image: url("https://static.vecteezy.com/system/resources/previews/003/214/844/non_2x/light-orange-background-with-colorful-stars-vector.jpg"); */
  background-color: #fdd301;
}
.rare-flat {
  background-color: #fdd301;
}
